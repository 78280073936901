import React from 'react';
import moment from 'moment';
import store from '../../../helpers/store'
import {
  uniq,
  map,
  merge,
  find,
  flatten,
  findKey,
  isEqual
} from 'lodash';
import {
  Dropdown
} from 'react-bootstrap';
import {
  connect
} from 'react-redux';

// import Tooltip from 'rc-tooltip';
import Slider from 'rc-slider';
import ReactTooltip from 'react-tooltip';
import Cookies from 'universal-cookie';

import Datepicker from '../../common/Datepicker';
import Multiselect from '../../common/Multiselect/Multiselect';
//import MultiselectWithSearch from '../../common/MultiselectWithSearch';
import Accordion from './Accordion';
import ReactSelect from '../../common/ReactSelect';
import FilterButton from './FilterButton';

import SaveLoad from '../../../helpers/SaveLoad';

import durations from './enums/durations.json';
import vendors from './enums/vendors.json';
import limits from './enums/limits.json';
import territories from './enums/territories.js';
import sources from './enums/sources.json';
import ranges from './enums/ranges.json';
import releaseDates from './enums/release_dates.json';
import genres from './enums/genres.json';
import contentTypes from './enums/content_types.json';
import audioFormats from './enums/audio_formats.json';
import Spinner from '../../common/Spinner';
import ClickOutsideHolder from '../../../helpers/ClickOutsideHolder'
import MatchMediaWrapper from '../../../helpers/MatchMediaWrapper';
import Tooltip from '../../common/Tooltip';
import ConfirmClick from '../../common/ConfirmClick';
import Modal from '../../common/Modal';
import config from '../../../config/config.js';

import {
  filterActions
} from '../../../data/actions/filter';

import {
    globalFilterActions
} from '../../../data/actions/global_filter';

import {
    userEntityFiltersActions
} from '../../../data/actions/user_entity_filters';

const dateFormat = 'YYYY-MM-DD';
const defaultLimit = 10;
const defaultDuration = 0;
const youtubeVendor = 'YouTube'; //todo: refactor to IDs
const vevoVendor = 'Vevo';
const vevoYoutubeVendor = 'Vevo - YouTube';

var toggleFilterSvg = require('!svg-inline-loader!../../../../public/img/filter-toggle.svg');
var fullCatalogueSvg = require('!svg-inline-loader!../../../../public/img/full-catalogue.svg');
var withoutHiddenSvg = require('!svg-inline-loader!../../../../public/img/without-hidden.svg');
var favoritesOnlySvg = require('!svg-inline-loader!../../../../public/img/favorites-only.svg');
var deleteSvg = require('!svg-inline-loader!../../../../public/img/icons/delete.svg');
var addSvg = require('!svg-inline-loader!../../../../public/img/add.svg');
var territoriesSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/territories.svg');
var sourcesSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/sources.svg');
var contentTypesSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/content-types.svg');
var vendorsSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/vendors.svg');
var releaseDateSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/release-date.svg');
var genresSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/genres.svg');
var engagementSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/engagement.svg');
var favoritesSvg = require('!svg-inline-loader!../../../../public/img/filter-icons/favorites.svg');
var arrowDownSvg = require('!svg-inline-loader!../../../../public/img/arrow-down.svg');

const endDateRef = React.createRef();
const prevEndDateRef =  React.createRef();
const releaseEndDateRef = React.createRef();
const startDateRef = React.createRef();
const modalRef = React.createRef();
const releaseDateWarningRef = React.createRef();
const updateFilterSettingsRef = React.createRef();

const generateFilterMenu = (filter) => {
  const getAmount = (key, total = 0) => {
      
    let item = filter[key];
    const isString = typeof item === 'string';
    const isBoolean = typeof item === 'boolean';

    if (!item) {
      if (isString) {
        return 0;
      } else {
        return '';
      }
    }

    if (isString) {
      item = item.split(',')
    }
    
    if(isBoolean)
        return item;

    if(item.length && item.length != total)
        return item.length;
    else
        return '';
  }
  
    return [
    {
      id: 1,
      name: "Territories",
      icon: territoriesSvg,
      amount: getAmount("territories"),
      accordionID: "territories"
    },
    { id: 2, name: "Platforms", icon: vendorsSvg, amount: getAmount("vendors", vendors.length), accordionID: "vendors" },
    { id: 3, name: "Sources", icon: sourcesSvg, amount: getAmount("sources"), accordionID: "sources" },
    { id: 4, name: "Content Types", icon: contentTypesSvg, amount: getAmount("contentType"), accordionID: "contentType" },
    { id: 5, name: "Genres", icon: genresSvg, amount: getAmount("genres"), accordionID: "genres" },
    {
      id: 6,
      name: "Release Date",
      icon: releaseDateSvg,
      amount: getAmount("release_date"),
      accordionID: "releaseDates"
    },
    {
      id: 7,
      name: "Engagement",
      icon: engagementSvg,
      amount: getAmount("active"),
      accordionID: "active"
    },
    {
      id: 8,
      name: "Favorites",
      icon: favoritesSvg,
      amount: getAmount("favorites"),
      accordionID: "filtered"
    },
    { id: 9, name: "Audio Formats", icon: sourcesSvg, amount: getAmount("audioFormats"), accordionID: "audioFormats" },
  ];
}

class Filter extends React.Component {
    constructor(props) {
      super(props);
      this.saveLoad = SaveLoad('global');
      let currentFilter = store.get('currentFilter');
      let isResetFilter = store.get('isResetFilter');
      const currentFilterID = store.get('currentFilterID');
      const defaultState = this._defaultState();
      
      if(currentFilter) {
        for (let key in defaultState) {
          if (!currentFilter.hasOwnProperty(key)) {
            currentFilter[key] = defaultState[key];
          }
        }

        currentFilter = this.filterAdjustDates(currentFilter);
        currentFilter.vendors = this._fixVendors(currentFilter.vendors);
        defaultState.filter = {...currentFilter};
      } else {
        defaultState.filter = this._defaultState().filter;
      }
      
      defaultState.allGenres = map(uniq(map(genres, genre => genre.primary_genre)), genre=>({label: genre, value: genre}));
      defaultState.genresMode = (defaultState.filter.genres && defaultState.filter.genres.includes('+')) ? 'and' : 'or'; 
      defaultState.currentFilterID = currentFilterID;
      defaultState.isResetFilter = isResetFilter;
      defaultState.cookies = new Cookies();
      defaultState.vevoYoutubeSelected = defaultState.filter.vendors.includes(vevoYoutubeVendor);

      this.state = defaultState;
      this.dateChange = this.dateChange.bind(this);
      this.prevDateChange = this.prevDateChange.bind(this);
      this.durationChange = this.durationChange.bind(this);
      this.releaseDateChange = this.releaseDateChange.bind(this);
      this.filterApply = this.filterApply.bind(this);
      this.genreChange = this.genreChange.bind(this);
      this.genreModeChange = this.genreModeChange.bind(this);
      this.engagementModeChange = this.engagementModeChange.bind(this);
      this.filteredModeChange = this.filteredModeChange.bind(this);
      this.filteredByModeChange = this.filteredByModeChange.bind(this);
      this._getRange = this._getRange.bind(this);
      this.getAllowedDays = this.getAllowedDays.bind(this);
      this.getMaxDate = this.getMaxDate.bind(this);
      this.filterSelectChange = this.filterSelectChange.bind(this);
      this.closeFilter = this.closeFilter.bind(this);
      this.getOverallFilterCount = this.getOverallFilterCount.bind(this);
      this.handleScroll = this.handleScroll.bind(this);
      this.filterToggleDefault = this.filterToggleDefault.bind(this); 
      this.filterAdjustDates = this.filterAdjustDates.bind(this);
      this.onDatepickerExpand = this.onDatepickerExpand.bind(this);
      this._getDateStart = this._getDateStart.bind(this);
      this._getSelectedDuration = this._getSelectedDuration.bind(this);
      this.renderToggleButton = this.renderToggleButton.bind(this);
      this.checkFilterUpdateDate = this.checkFilterUpdateDate.bind(this);
      this.toggleVevoYoutubeVendor = this.toggleVevoYoutubeVendor.bind(this);
    }

    componentWillMount(){
        this.filterApply(undefined, this.state.filter);
    }
    
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.metadataMinDate && prevProps.metadataMinDate != this.props.metadataMinDate) {
            const { durationIndex } = this.state;
            if(durationIndex == 5) {
                this.durationChange(0);
            }
        }
    }

    _getDateStart(dateEnd, durationIndex) {
        const selectedDuration = find(durations, duration=>duration.value == durationIndex);
      let {
        offset
      } = selectedDuration;
      if(!offset)
          return null;
      if(offset.days && offset.days == -1) {
          return this.props.metadataMinDate ? moment(this.props.metadataMinDate) : moment(this.props.userMinDate);
      }
      else {
          return moment(dateEnd).subtract(offset);
      }
    }
    
    _getSelectedDuration(dateStart, dateEnd, forDuration = false) {
        const { metadataMinDate, userMinDate } = this.props;
        if(forDuration && (metadataMinDate == dateStart || userMinDate == dateStart)) {
            return -1;
        }
      const dateDiff =  moment(dateEnd).diff(moment(dateStart), 'days');
      return moment.duration(dateDiff, 'days').asDays();
    }

    _getDuration() {
      const {dateStart, dateEnd} = this.state.filter;
      const selectedDuration = this._getSelectedDuration(dateStart, dateEnd, true);
      for (let duration of durations) {
        if (duration.offset && duration.offset.days == selectedDuration)
          return duration.value;
      }
      return durations[durations.length - 1].value;
    }
    
    _getReleaseDate() {
        const {releaseStart, releaseEnd} = this.state.filter;
        const today = moment();
        const selectedDurationStart = this._getSelectedDuration(releaseStart, today);
        const selectedDurationEnd = this._getSelectedDuration(releaseEnd, today);
        for (let date of releaseDates) {
          if (date.offset) {
              if(((date.offset[1].days == selectedDurationStart) || (! date.offset[1].days && !releaseStart )) 
                  && ((date.offset[0].days == selectedDurationEnd)  || (! date.offset[0].days && !releaseEnd )))
              return date.value;    
          }
        }
        return releaseDates[releaseDates.length - 1].value;
    }


    _getRange({dateStart, dateEnd}) {
        let selectedDuration = this._getSelectedDuration(dateStart, dateEnd),
            selectedRange = ranges[0];

        for(let rangeID = ranges.length-1; rangeID>=0; rangeID--) {
            if(ranges[rangeID].range <= selectedDuration) {
                selectedRange = ranges[rangeID];
                break;
            }
        }

        let newRange = selectedRange.value;
        if(this.state) {
            let currentRange = this.state.filter.range;
            if((newRange == 'clwk' && currentRange == 'chwk') || (newRange == 'chwk' && currentRange == 'clwk'))
                newRange = currentRange;
        }
        return newRange;
    }

    _getPrevDateRange({dateStart, dateEnd}) {
        const duration = this._getSelectedDuration(dateStart, dateEnd, true),
            prevDateEnd = moment(dateStart).subtract(1, 'days');
        let prevDateStart;
        if(duration == -1)
            prevDateStart = prevDateEnd;
        else
            prevDateStart = prevDateEnd.clone().subtract(duration, 'days');

        return {
            prevDateStart: prevDateStart.format(dateFormat),
            prevDateEnd: prevDateEnd.format(dateFormat)
        }
    }

    _getDefaultEndDate() {
      return moment().subtract(2, 'days');
    }
    
    _getDefaultVendors() {
        return map(vendors.filter(vendor=>vendor.value!==vevoYoutubeVendor), (vendor)=>vendor.value);
    }
    
    _fixVendors(currentVendors = []) {
        if(currentVendors.length) {
            return currentVendors.filter(vendor=>vendor!==vevoYoutubeVendor);
        }
        else
            return this._getDefaultVendors();
    }

    _defaultState() {
      let dateEnd = this._getDefaultEndDate(),
        dateStart = this._getDateStart(dateEnd, defaultDuration),
        {prevDateStart, prevDateEnd} = this._getPrevDateRange({dateStart, dateEnd}),
        releaseEnd = null,
        releaseStart = null;

      return {
        filter: {
          dateStart: dateStart.format(dateFormat),
          dateEnd: dateEnd.format(dateFormat),
          prevDateStart: prevDateStart,
          prevDateEnd: prevDateEnd,
          releaseStart: releaseStart,
          releaseEnd: releaseEnd,
          territories: [],
          sources: [],
          contentType: [],
          genres: '',
          audioFormats: [],
          vendors: this._getDefaultVendors(),
          limit: defaultLimit,
          range: 'day',
          active: false,
          filtered: undefined,
          filteredBy: 'imprints',
          forcePrev: false
        },
        //currentFilterName: "currentFilter",
        currentFilterID: null,
        scrollTop: 0,
        currentFilter: {},
        advancedFilterOpened: false,
        releaseDateWarningModal: false,
        filterSettingsModal: false,
        durationIndex: 0,
        datepickerContainerHeight: 0,
        isExpanded: false,
        isReset: false,
        accordion: {
          dates: true,
          territories: false,
          sources: false,
          contentType: false,
          genres: false,
          vendors: false,
          limit: false,
          range: false,
          filters: true,
          active: false,
          shared: false,
          releaseDates: false,
          audioFormats: false,
          filtered: false
        }
      }
    }

    prevDateChange(date, propDate) {
        date = moment(date);
        let filter = {
          ...this.state.filter,
        },
        duration = this._getSelectedDuration(filter.dateStart, filter.dateEnd),
        prevDateStart,
        prevDateEnd;

        if(propDate == 'prevDateStart') {
            prevDateStart = date,
            prevDateEnd = date.clone().add(duration, 'days');
        }
        else {
            prevDateEnd = date,
            prevDateStart = date.clone().subtract(duration, 'days');
        }
        filter.prevDateStart = prevDateStart.format(dateFormat);
        filter.prevDateEnd = prevDateEnd.format(dateFormat);

        this.setState({...this.state, filter});
        
        if(propDate == 'prevDateStart')
            prevEndDateRef.current.setOpen(true);          
    }


    dateChange(date, propDate) {
      date = moment(date).format(dateFormat);
      const filter = {
        ...this.state.filter,
        [propDate]: date
      },
      {prevDateStart, prevDateEnd} = this._getPrevDateRange(filter);
      filter.range = this._getRange(filter);
      filter.prevDateStart = prevDateStart;
      filter.prevDateEnd = prevDateEnd;

      this.setState({...this.state, filter});
      
      if (propDate == 'dateStart')
          endDateRef.current.setOpen(true);

      if (propDate == 'releaseStart')
        releaseEndDateRef.current.setOpen(true)
    }

    durationChange(durationIndex) {
        const { user } = this.props;
        const { durationIndex: currentDurationIndex } = this.state;
      let dateEnd = this._getDefaultEndDate(),
        dateStart = this._getDateStart(dateEnd, durationIndex);
      
      if (!dateStart) {
        const adjustDate = currentDurationIndex == 4 ? 0 : 1; // don't adjust date if already in custom mode
        dateStart = moment(this.state.filter.dateStart).add(adjustDate, 'days');
      }

      let releaseDateWarningModal = false;
      
      //if(durationIndex == 5 && dateStart.isSame(user.first_data_date))
          //releaseDateWarningModal = true;
      
      const filter = {
        ...this.state.filter,
        dateStart: dateStart.format(dateFormat),
        dateEnd: dateEnd.format(dateFormat)
      },
      {prevDateStart, prevDateEnd} = this._getPrevDateRange(filter);
      filter.range = this._getRange(filter);
      filter.prevDateStart = prevDateStart;
      filter.prevDateEnd = prevDateEnd;

      this.setState({
        ...this.state,
        filter,
        releaseDateWarningModal,
        durationIndex
      });
    }
    
    releaseDateChange(dateIndex) {
        
        const {offset} = releaseDates[dateIndex];
        if(offset) {
            let releaseEnd = offset[0].days !== null ? moment().subtract(offset[0].days, 'days') : null,
                releaseStart = offset[1].days !== null ? moment().subtract(offset[1].days, 'days') : null;
            
                
            const filter = {
                ...this.state.filter,
                releaseStart: releaseStart ? releaseStart.format(dateFormat) : '',
                releaseEnd: releaseEnd? releaseEnd.format(dateFormat) : ''
              };
            this.setState({
                ...this.state,
                filter
              });
        }
      }


    filterChange = (value, prop) => {
      const filter = {
        ...this.state.filter,
        [prop]: value
      };


      this.setState({
        ...this.state,
        filter,
        currentFilterID: null // as value is changed, we drop selected filter
      });
    }

    filterReset = () => {
      const defaultState = this._defaultState();
      defaultState.isReset = true;
      this.setState(defaultState);
      store.set('isResetFilter', defaultState.isReset);
    }

    filterSave = (e) => {
      e.preventDefault();

      const filterName = prompt('Please enter a name for your filter.');
      if (filterName !== '' && filterName !== null) {
        this.props.createFilter(this.state.filter, filterName)
        .then(id=>{this.setState({currentFilterID: id})});
      }
    }
    
    filterAdjustDates(filter){
        let duration = this._getSelectedDuration(filter.dateStart, filter.dateEnd),
            dateEnd = this._getDefaultEndDate(),
            dateStart = dateEnd.clone().subtract(duration, 'days');
    
          let {prevDateStart, prevDateEnd} = this._getPrevDateRange({dateStart, dateEnd});
          filter.prevDateStart = prevDateStart;
          filter.prevDateEnd = prevDateEnd;
          filter.dateStart = dateStart.format(dateFormat);
          filter.dateEnd = dateEnd.format(dateFormat);
          filter.range = this._getRange(filter);
          return filter;
    }

    filterLoad = (filter, filterID) => {
        filter = this.filterAdjustDates(filter);
        filter.vendors = this._fixVendors(filter.vendors);
        this.setState({
            filter: {...filter},
            currentFilterID: filterID
        });

        store.set('currentFilterID', filterID);
    }

    filterApply(event, filter) {
      event && event.preventDefault();
      const { currentFilterID } = this.state;
    //  const filterNames = this.props.savedFilters.map(filter => filter.filter_name );
//      const filter_name = filterNames.includes(this.state.currentFilterName) && this.state.currentFilterName;
      this.props.setGlobalFilter({...filter}, currentFilterID);

      if (this.state.isReset) {
        store.set('currentFilterID', null);
      } else {
        store.set('currentFilterID', currentFilterID)
      }
    }

    // closeFilter() {
    //   this.props.toggleGlobalFilter();
    // }

    filterShare = (id, shared) => {
        // we get filter's initial state, so we don't overwrite it intentionally
        let filter = find(this.props.savedFilters, {id});
        filter.is_shared = shared;
        return this.updateFilter(id, filter);
    }
    
    filterToggleDefault = (id, isDefault) => {
        let filter = find(this.props.savedFilters, {id});
        filter['default'] = isDefault;
        return this.props.setDefaultFilter(id, isDefault);
    }

    toggleAccordion = (selectedKey) => {
      let {
        accordion
      } = this.state;
      for (let key in accordion) {
        accordion[key] = (key == selectedKey) ? !accordion[key] : false;
      }
      this.setState({
        accordion
      })
    }

    removeFilter = id => {
      this.filterReset();
      this.props.onRemoveFilter(id);
    }

    updateFilter = (id, filter=this.state.filter) => {
      this.props.onUpdateFilter(id, filter);
    }

    componentDidMount() {
      const { getFilters, getSystemFilter, getEntityFilters, user } = this.props;

      const silo = user.primary_silo_code;
      getFilters(true);
      getEntityFilters();
      getSystemFilter();

      window.addEventListener('scroll', this.handleScroll);
    }

//    componentDidUpdate(prevProps){
//      if(this.props.savedFilters.length !== prevProps.savedFilters.length){
//        const filter = this.props.savedFilters.find( filter => filter.filter_name === this.state.filter.filter_name);
//        if(filter)
//          this.filterLoad({ ...filter.filter_body, is_shared: filter.is_shared }, filter.filter_name)
//      }
//    }

    componentDidUpdate(prevProps) {
      if(this.props.savedFilters.length !== prevProps.savedFilters.length){
        this.checkFilterUpdateDate();
      }
      
      if(!isEqual(this.props.filter.global, prevProps.filter.global)){
        this.setState({
          ...this.state,
          filter: {...this.props.filter.global}
        })
      }
    }

    componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
      this.setState({
        scrollTop: window.scrollY
      })
    }

    getAllowedDays(component){
        const {range} = this.state.filter;
        let allowedDay = '';
        /*
        switch(range){
            case 'chwk':
                allowedDay = component == 'start' ? '5' : '4';
            break;
            case 'clwk':
                allowedDay = component == 'start' ? '1' : '0';
            break;
        }
        */
        return allowedDay ? '0123456'.replace(allowedDay, '') : '';
    }

    getMaxDate(field) {
        let maxDate,
            {filter} = this.state;
        switch(field) {
            case 'dateStart':
                maxDate = moment(filter.dateEnd).subtract(1, 'days');
                break;

            case 'prevDateEnd':
                maxDate = moment(filter.dateStart).subtract(1, 'days');
                break;

            case 'prevDateStart':
                maxDate = moment(filter.dateStart).subtract(this._getSelectedDuration(filter.dateStart, filter.dateEnd)+1, 'days');
                break;

            case 'releaseStart':
                maxDate = filter.releaseEnd ? moment(filter.releaseEnd).subtract(1, 'days') : moment();
                break;
                
            case 'dateEnd':
            case 'releaseEnd':
                maxDate = moment();
                break;
        }
        return maxDate.format(dateFormat);
    }
    vendorChange(value){
        if(Array.isArray(value) && value.length==0)
            value = this._getDefaultVendors();
        let {vevoYoutubeSelected} = this.state;
        if(value.includes(youtubeVendor) || !value.includes(vevoVendor)) {
          vevoYoutubeSelected = false;
          value = value.filter(vendor=>vendor!=vevoYoutubeVendor)
        }   
        if(vevoYoutubeSelected && !value.includes(vevoYoutubeVendor))
          value.push(vevoYoutubeVendor);
         
        this.setState({
          vevoYoutubeSelected
        }, ()=>{
          this.filterChange(value, 'vendors');  
        });
    }
    
    toggleVevoYoutubeVendor(e){
      const {checked} = e.target;
      let {vendors} = this.state.filter;
      if(checked) {
        vendors = vendors.filter(vendor=>vendor!=youtubeVendor);
      }
      this.setState({
        vevoYoutubeSelected: checked
      }, ()=>{
        this.vendorChange(vendors)
      });
    }

    deselectAllVendorFilter() {
      this.filterChange([], 'vendors');
    }

    selectAllTerritoriesFilter(value) {
      if (Array.isArray(value) && value.length == 0) {
        value = territories;
      }
        
      const flattenArray = _.map(_.flatMapDeep(value), (v) => v.value);
      this.filterChange(flattenArray, "territories");
    }

    deselectAllTerritoriesFilter() {
      this.filterChange([], "territories");
    }
    
    selectAllSourcesFilter() {
        this.filterChange(sources, 'sources');
      }
    

    deselectAllSourcesFilter() {
        this.filterChange([], 'sources');
      }
    
    selectAllAudioFormatsFilter() {
        this.filterChange(audioFormats, 'audioFormats');
      }
    

    deselectAllAudioFormatsFilter() {
        this.filterChange([], 'audioFormats');
      }
    
    selectAllContentTypesFilter() {
        const allContentTypes = map(contentTypes, 'value');
        this.filterChange(allContentTypes, 'contentType');
      }
    

    deselectAllContentTypesFilter() {
        this.filterChange([], 'contentType');
      }

    
    limitChange(value){
        value = limits[value];
        this.filterChange(value, 'limit');
    }
    
    genreChange(values){
        let genres = '';
        if(values) {
            genres = values.reduce((str, genre)=> {
                str += str ? ',' : '';
                str += genre.value;
                return str;
            }, '');
        }
        this.filterChange(genres, 'genres');
    }
    
    genreModeChange(mode) {
        let currentGenres = this.state.filter.genres,
            findStr, replaceStr;
        if(mode == 'and') {
            findStr = ',';
            replaceStr = '+';
        }
        else {
            findStr = '+';
            replaceStr = ',';
        }
        
        const genres = currentGenres.split(findStr).join(replaceStr);
        
        this.setState({
            ...this.state,
            genresMode: mode,
            filter: {
                ...this.state.filter,
                genres
            },
            currentFilterID: null
        })
    }

    engagementModeChange(active) {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                active
            },
            currentFilterID: null
        })
    }
    
    filteredModeChange(filtered) {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filtered
            },
            currentFilterID: null
        })
    }
    
    showReleaseDateWarningModal = (releaseDateWarningModal) => {
      this.setState({releaseDateWarningModal})
    } 
    
    showUpdateFilterSettingsModal = (filterSettingsModal) => {
      this.setState({filterSettingsModal})
    } 
    
    filterSelectChange = (selectedOption) => {
      const selectedFilter = this.props.savedFilters.find(
        (filter) => filter.id === selectedOption.value
      ) || {};
//
//      this.setState({
//        selectedOption: { ...selectedOption, ...selectedFilter },
//      });
        
        // store.set('currentFilterID', selectedFilter.id);
      
      let { filter_body } = selectedFilter;
      if(filter_body.hasOwnProperty('filter_body'))
          filter_body = filter_body.filter_body;


      this.filterLoad({ ...filter_body }, selectedFilter.id)

      this.checkFilterUpdateDate();
      this.setState({isReset: false})
      store.set("isResetFilter", this.state.isReset)
    };

    filteredByModeChange(filteredBy) {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                filteredBy
            }
        })
    }


    renderDateRange(readonly){
        const { range, forcePrev } =  this.state.filter,
            selectedRange = find(ranges, {value: range}),
            isWeekMode = range == 'chwk' || range == 'clwk',
            isMonthMode = range == 'mth';

        return <div className="date-range-additional">
            <label className="small-text">Date Group: {selectedRange.label}</label>
            {isWeekMode && <div className="date-range-holder">
              <div className="option option-item">
                <div className="filter-radio-holder">
                  <input type="radio" disabled={readonly} name="week_mode" className="visually-hidden" id="chart_week" checked={(range=='chwk')} onChange={()=>this.filterChange('chwk', 'range')} />
                  <label htmlFor="chart_week" className="small-text">Chart Week</label>
                </div>
              </div>
              <div className="option option-item">
                <div className="filter-radio-holder">
                  <input type="radio" disabled={readonly} name="week_mode" className="visually-hidden" id="calendar_week" checked={(range=='clwk')} onChange={()=>this.filterChange('clwk', 'range')} />
                  <label htmlFor="calendar_week" className="small-text">Calendar Week</label>
                </div>
              </div>
            </div>}
            {isMonthMode && <div className="date-range-holder">
                <div className="option option-item">
                  <div className="filter-radio-holder">
                  <input type="checkbox" id="force_prev" className="toogle-input visually-hidden"
                      defaultChecked={forcePrev}
                      onChange={(e) => this.filterChange(e.target.checked, 'forcePrev')}
                      />
                    <label htmlFor="force_prev" className="toogle-input">Include Previous Range</label>
                    </div>  
                </div>
              </div>}
            
        </div>;
    }
    
    closeFilter(e, filterOpened){
      const { toggleFilterOpened } = this.props;
      
      toggleFilterOpened();
      this.props.toggleGlobalFilter();
      
      if (filterOpened)
        this.filterApply(e, this.state.filter);
    }

    getOverallFilterCount = () => {
      const amountArr = generateFilterMenu(this.state.filter).map(item => item.amount);
      
      let numbersOnly = (val) => {
        if (typeof(val) === 'number') {      
          return val;
        }
      }

      let amountArrNum = amountArr.filter(numbersOnly);

      const reducer = (accumulator, curr) => accumulator + curr;
      const sum = amountArrNum.length ? amountArrNum.reduce(reducer) : null;

      const count = sum <= 99 ? sum : 99;

      this.props.callBackFromMain(count)
    }

    toggleAdvancedFilter = () => {
      this.setState({
        advancedFilterOpened: !this.state.advancedFilterOpened,
        accordion: {
          ...this.state.accordion,
          dates: true,
        }
      })
    }
    
    hideFilterWarningPopup = () => {
      const { cookies } = this.state;
      let expires = new Date();
      expires.setFullYear(expires.getFullYear() + 1);
      this.state.cookies.set('hideFilterWarning', true, {path: '/', expires });
    }
    
    onDatepickerExpand(datepickerContainer, datepickerState){
      this.setState({
        datepickerContainer,
        datepickerContainerHeight: datepickerState.height,
        isExpanded: datepickerState.expanded
      })
    }
    
    renderToggleButton(){
        const { dateStart, dateEnd, range } = this.state.filter;
        const tooltipFormat = range == 'mth' ? 'YYYY-MMM' : 'YYYY-MM-DD'; 
        const displayFormat = range == 'mth' ? 'MMM YYYY' : 'MMM DD';
        return <React.Fragment><span className="toggle-btn-date-item" title={`${moment(dateStart).format(tooltipFormat)} - ${moment(dateEnd).format(tooltipFormat)}`}>{moment(dateStart).format(displayFormat)} - {moment(dateEnd).format(displayFormat)}</span></React.Fragment>;
    }
	
	renderFilterSummary() {
		const { filter } = this.state;
		let changedOptions = {};
		
		let totalTerritories = 0;
		for(let continent of Object.keys(territories)) {
			totalTerritories += territories[continent].length;
		}
		
		if(filter.vendors && filter.vendors.length) {
			let vendorsCount = filter.vendors.length == ( vendors.length - 1) ? 0 : filter.vendors.length;
			if(vendorsCount) {
				changedOptions['Platforms'] = vendorsCount;
			}
		}
			
		if(filter.territories && filter.territories.length) {
			let territoriesCount = filter.territories.length == totalTerritories ? 0 : filter.territories.length;
			if(territoriesCount) {
				changedOptions['Territories'] = territoriesCount;
			}

		}
		
		if(filter.genres && filter.genres.length) {
			let genresCount = filter.genres.split(',').length;
			if(genresCount) {
				changedOptions['Genres'] = genresCount;
			}
		}
		if(filter.sources && filter.sources.length) {
			let sourcesCount = filter.sources.length == sources.length ? 0 : filter.sources.length;
			if(sourcesCount) {
				changedOptions['Sources'] = sourcesCount;
			}			
		}
		
		if(filter.contentType && filter.contentType.length) {
			let contentTypesCount = filter.contentType.length == contentTypes.length ? 0 : filter.contentType.length;
			if(contentTypesCount) {
				changedOptions['Content Types'] = contentTypesCount;
			}			
		}
		
		if(filter.audioFormats && filter.audioFormats.length) {
			let audioFormatsCount = filter.audioFormats.length == audioFormats.length ? 0 : filter.audioFormats.length;
			if(audioFormatsCount) {
				changedOptions['Audio Formats'] = audioFormatsCount;
			}			
		}
		
		if(filter.active)
			changedOptions['Engagement'] = '+';

		if(filter.releaseStart || filter.releaseEnd)
			changedOptions['Release Date'] = '+';
			
		if(!Object.keys(changedOptions).length)
			return null;
			
		return <div className="filter-title-tooltip">
		  <span data-tip data-for="filter-summary-tip" className="notificationReportInfoIcon toolbar-title-tooltip-icon"> 
			  {Object.keys(changedOptions).length} 
		  </span>
		  <ReactTooltip effect="solid" multiline={true} place="top" className="top-sections-tooltip" id="filter-summary-tip">
			{Object.keys(changedOptions).map(option=>{
				return <p key={option}>{`${option}: ${changedOptions[option]}`}</p>;
			})}
		  </ReactTooltip>
		</div>	
	}

  checkFilterUpdateDate() {
    const { cookies } = this.state;
    const hideFilterWarning = cookies.get('hideFilterWarning');
    
    if (this.props.savedFilters.length && this.state.currentFilterID) {
      const filterItem = this.props.savedFilters.find(({id}) => id == this.state.currentFilterID);
      const comparisonDate = moment(config.comparisonDate);
      
      if (filterItem) {
        const updatedAtDate = moment(filterItem.updated_at);
        const filterDateDiff = comparisonDate.diff(updatedAtDate, 'days');
        
        if (filterDateDiff > 1 && !hideFilterWarning) {
          this.setState({
            filterSettingsModal: true
          })
        }
      }
    }
  }

    render() {
        const { readonly = false } = this.props;
        const { logs } = this.props.filter;
        const { cookies } = this.state;
        const hideFilterWarning = cookies.get('hideFilterWarning');

        let reportDates =  uniq(map(logs, 'report_date')).sort();

        const renderSavedFilters = () => {
          const { currentFilterID } = this.state;
          const mapSavedFilters = this.props.savedFilters && this.props.savedFilters.map(filter => ({label: filter.filter_name, value: filter.id, user_id: filter.user_id, is_shared: filter.is_shared, 'default': filter['default']}))
          const selectedOption = find(mapSavedFilters, filter=>filter.value == currentFilterID) || null;
          return <div>
            <div className="saved-filters-container">
              <ReactSelect options={mapSavedFilters} placeholder="Choose Filter" onChange={this.filterSelectChange} value={selectedOption} className="select-wrapper" />
              <a onClick={(e)=>this.filterSave(e)} className="add-filter-btn">
                <span className="add-filter-btn-icon" dangerouslySetInnerHTML={{__html: addSvg}}></span>
              </a>
            </div>
            { selectedOption &&
              <li key={selectedOption.value} className={`main-filter-item ${selectedOption.value == this.state.currentFilterID ? "active" : "" }`}>
                <div className="">
                  {this.props.user.id === selectedOption.user_id ? (
                    <div className="saved-filter-actions">
                      {selectedOption.value == this.state.currentFilterID && (
                        <div>
                          <span onClick={() => this.removeFilter(selectedOption.value)} className="removeAction remove-filter-link">
                            <span className="tiny-icon" dangerouslySetInnerHTML={{__html: deleteSvg}}></span>
                            Delete Filter
                          </span>
                          {/* <div>
                            <span onClick={() => this.updateFilter(selectedOption.id)} className="removeAction">
                              Replace with current
                            </span>
                          </div> */}
                        </div>
                      )}
                      {/*this.props.user.client_admin && */selectedOption.value == this.state.currentFilterID && (
                        <div className="filterShared">
                          <input type="checkbox" id="share-filter" className="toogle-input visually-hidden" disabled={selectedOption.value != this.state.currentFilterID }
                            defaultChecked={selectedOption.is_shared}
                            onChange={(e) => this.filterShare(selectedOption.value, e.target.checked)}
                            />
                          <label htmlFor="share-filter" className="toogle-input">Share</label>
                        </div>
                      )}
                      {selectedOption.value == this.state.currentFilterID && (
                          <div className="filterShared">
                            <input type="checkbox" id="default-filter" className="toogle-input visually-hidden" disabled={false}
                              defaultChecked={selectedOption['default']}
                              onChange={(e) => this.filterToggleDefault(selectedOption.value, e.target.checked)}
                              />
                            <label htmlFor="default-filter" className="toogle-input">Default</label>
                          </div>
                        )}
                    </div>
                  ) : null}
                </div>
              </li>
            }
          </div>
        }

        const filter = this.state.filter,
            selectedTerritories = filter.territories ? filter.territories.slice(0) : [],
            selectedVendors = filter.vendors ? filter.vendors.slice(0) : [],
            selectedDuration = this._getDuration(),
            selectedReleaseDate = this._getReleaseDate(),
            selectedSources = filter.sources ? filter.sources.slice(0) : [],
            selectedAudioFormats = filter.audioFormats ? filter.audioFormats.slice(0) : [],
            selectedContentType = filter.contentType ? filter.contentType.slice(0) : [],
            selectedRange = this.state.filter.range,
            selectedGenresMode = this.state.genresMode,
            selectedEngagementMode = filter.active,
            selectedFilteredMode = filter.filtered,
            selectedFilteredByMode = filter.filteredBy;
            
        let selectedGenres = [];    
        if(filter.genres) {
            selectedGenres = map(filter.genres.split(selectedGenresMode == 'and' ? '+' : ',') , genre=>({label: genre, value: genre}));
        }
            
        let sliderValue = 0,
            sliderLength = 0;
        for(let valueIndex of Object.keys(limits)) {
            sliderLength++;
            if(limits[valueIndex]==filter.limit)
                sliderValue = valueIndex;
        };
        sliderValue = Number(sliderValue);
        
        let sourceValues = sources.map(source => ({
            label: source,
            value: source
        }));
        let audioFormatValues = audioFormats.map(format => ({
            label: format,
            value: format
        }));
        
        const vendorsForFilter = vendors.filter(vendor=>vendor.vend_id != 24).map(vendor=>{
          if(vendor.vend_id == 23) {
            const { vevoYoutubeSelected } = this.state;
            vendor.extraComponent = <div className="filterShared">
              <input type="checkbox" id="share-filter" className="toogle-input visually-hidden" disabled={false}
                checked={vevoYoutubeSelected}
                onChange={this.toggleVevoYoutubeVendor}
                />
              <label htmlFor="share-filter" className="toogle-input">Include YouTube</label>
            </div>
          } else {
            vendor.extraComponent = null;
          }
          
          return vendor;
        });
        

        const filterOpened = this.props.isFilterOpened;
        const filterChanged = !isEqual(filter, this.props.filter.global);
        const scrollFilterClass = this.state.scrollTop > 10 ? "scrolled" : "";

        const dateDiff = moment(filter.dateEnd).diff(moment(filter.dateStart), 'days');
        const isConfirmModal = this._getDuration() > 1 && dateDiff > 82 ? true : false;
        const confirmPrompt = <div className="filter-confirm-modal-text">
          <h4 className="filter-confirm-modal-title">That's a lot of data</h4>
          <div>Heads up, this search may take longer than usual. Choose Cancel to refine your date range or OK to proceed</div>
        </div>

        const extraRefs = [modalRef, releaseDateWarningRef] ;// isConfirmModal ? [modalRef] : [];

        // temp remove "released to date"
        //const newDurationsArray = durations.map((duration) => {
        const newDurationsArray = durations
        //.filter((duration)=>duration.value != 5)
        .map((duration) => {
          if (duration.offset && duration.offset !== null) {
              const startDate = this._getDateStart(filter.dateEnd, duration.value);
            duration.startDate = startDate ? startDate.format('MMM DD') : '';
          }

          return duration;
        });
        

        const diff = this.renderToggleButton();
        const defaultDurationLabel = durations.find(duration => (selectedDuration === duration.value)).label;
        const durationLabel = selectedDuration !== 4 ? defaultDurationLabel : diff

        const { filterClass } = this.props;
        const dateAccordionClassName = this.state.advancedFilterOpened ? "date-opened" : "not-accordion"

        const datepickerMargin = this.state.isExpanded ? this.state.datepickerContainerHeight : 0;
        const { datepickerContainer } = this.state;

        return <div className={`filter-wrapper ${filterOpened ? 'filter-opened' : ''} ${filterClass}`}>
          <Modal show={(this.state.filterSettingsModal && !hideFilterWarning)} 
            title="Update Filter Settings" 
            showActionButtons={false}
            handleClose={()=>this.showUpdateFilterSettingsModal(false)}
            onRequestClose={(e)=>{
              e.preventDefault();
              e.stopPropagation();
              this.showUpdateFilterSettingsModal(false)
              this.hideFilterWarningPopup()}}
            dialogClassName="dialog-modal release-modal"
            // modalRef={updateFilterSettingsRef}
          >
              <p className="export-modal">
                New options have been added to the filter settings. Your filter has not been updated for a long time. Check if something else needs to be added. <br /><br />
                <div className="checkbox-toggle-holder">
                  <input type="checkbox" id="dont_show" className="toogle-input visually-hidden"
                      onChange={(e) => console.log("dont show checked")}
                      />
                  {/* <label htmlFor="dont_show" onClick={this.hideFilterWarningPopup}>Don't show again</label> */}
                </div> 
                
                <button type="button" className="default-btn default-btn--small footer-modal-submit" onClick={(e)=> {this.showUpdateFilterSettingsModal(false);this.hideFilterWarningPopup()}}>OK</button>
              </p>
          </Modal>
          <div className="toggle-filter-holder">
		  	    {this.renderFilterSummary()}
            <div className="toggle-filter-btn" onClick={(e)=>this.props.toggleFilterOpened()} >{durationLabel}<span dangerouslySetInnerHTML={{__html: arrowDownSvg}} /></div>
          </div>
          {this.props.isFilterOpened && <ClickOutsideHolder show={true} externalModal={this.state.filterSettingsModal} extraRefs={extraRefs} onClickOutside={(e) => {
            e.stopPropagation();
            if (!this.state.filterSettingsModal) 
              this.props.toggleFilterOpened()
          }}>
          <div className="filter-summary-holder">            
            <div className="filterSpinner">
              <Spinner enabled={this.props.loading} />
            </div>
              <Modal show={this.state.releaseDateWarningModal} 
                  title="Release Date Warning" 
                  showActionButtons={false}
                  handleClose={()=>this.showReleaseDateWarningModal(false)}
                  dialogClassName="dialog-modal release-modal"
                  modalRef={releaseDateWarningRef}
              >
                  <p className="export-modal">
                      Your filter's minimal release date precedes your first data collection date. <br /><br />
                      <button type="button" className="default-btn default-btn--small footer-modal-submit" onClick={(e)=> {this.showReleaseDateWarningModal(false);}}>OK</button>
                  </p>
                  
              </Modal>
              
            <form role="form" className="form-inline">
                <div className="filter-wrapper-inner">
                <Accordion className={`chosen-group filter-option-group ${dateAccordionClassName}`} id="dates" label="Date Range" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                    <div className="daterange-options">
                      <Multiselect readonly={readonly} elements={newDurationsArray} selected={selectedDuration} handleChange={this.durationChange} options={{disable_search: true}} isDateRange dateEndValue={moment(filter.dateEnd).format("MMM DD")} />
                    </div>
                    <div className="daterange-item daterange-item--with-tooltip">
                      <label className="small-text daterange-label"><b>Current Date Range</b>
                      <div className="table-header-tooltip">
                          <Tooltip position="bottom" message={<div className="dots-text">Dates that appear with a dot above them (<span className="dots-image"></span>) denote missing or partial data from the top providers: Spotify, Apple Music, YouTube, or Amazon. See the missing data flag at the top of any page for more details.</div>} tooltipClass="toolbar-title-tooltip"/>
                      </div>
                      </label>
                      <div className="daterange-container" style={{marginBottom: (datepickerContainer=='previous'?datepickerMargin:null)}}>
                        <Datepicker readonly={readonly} endDate={this.getMaxDate('dateStart')} date={filter.dateStart} currentStartDate={filter.dateStart} currentEndDate={filter.dateEnd} label="From" dateChange={(date)=>this.dateChange(date, 'dateStart')} daysOfWeekDisabled={this.getAllowedDays('start')} showYearDropdown selectsStart={true} monthsShown={1} calendarStartDay={5} showWeekNumbers={true} reportDates={reportDates} onExpand={(datepickerProps)=>this.onDatepickerExpand('previous', datepickerProps)} dateref={startDateRef} />
                        <span className='fas fa-long-arrow-alt-right'></span>
                        <Datepicker readonly={readonly} endDate={this.getMaxDate('dateEnd')} date={filter.dateEnd} currentStartDate={filter.dateStart} currentEndDate={filter.dateEnd} label="To" dateChange={(date)=>this.dateChange(date, 'dateEnd')} className="form-control text-right" daysOfWeekDisabled={this.getAllowedDays('end')} showYearDropdown selectsEnd={true} monthsShown={1} dateref={endDateRef} calendarStartDay={5} showWeekNumbers={true} reportDates={reportDates} datepickerPosition="bottom-end" onExpand={(datepickerProps)=>this.onDatepickerExpand('previous', datepickerProps)} />
                      </div>
                    </div>
                    <div className="daterange-item">
                      <label className="small-text daterange-label">Previous Date Range</label>
                      <div className="daterange-container prev-range" style={{marginBottom: (datepickerContainer=='current'?datepickerMargin:null)}}>
                          <Datepicker readonly={readonly} endDate={this.getMaxDate('prevDateStart')} date={filter.prevDateStart} label="Prev From" dateChange={(date)=>this.prevDateChange(date, 'prevDateStart')} daysOfWeekDisabled={this.getAllowedDays('start')} showYearDropdown calendarStartDay={5} showWeekNumbers={true} monthsShown={1} reportDates={reportDates} onExpand={(datepickerProps)=>this.onDatepickerExpand('current', datepickerProps)} dateref={startDateRef} />
                          <span className='fas fa-long-arrow-alt-right'></span>
                          <Datepicker readonly={readonly} endDate={this.getMaxDate('prevDateEnd')} date={filter.prevDateEnd} label="Prev To" dateChange={(date)=>this.prevDateChange(date, 'prevDateEnd')}  daysOfWeekDisabled={this.getAllowedDays('end')} className="form-control text-right" showYearDropdown  dateref={prevEndDateRef} calendarStartDay={5} showWeekNumbers={true} monthsShown={1} reportDates={reportDates} datepickerPosition="bottom-end" onExpand={(datepickerProps)=>this.onDatepickerExpand('current', datepickerProps)}  />
                      </div>
                    </div>
                    {this.renderDateRange(readonly)}
                    </Accordion>

                    <div className="advanced-filter">
                      <div className="advanced-toggle-btn default-btn default-btn--light" onClick={this.toggleAdvancedFilter}>Advanced</div>
                      {this.state.advancedFilterOpened && 
                      <div className="advanced-filter-inner">

                        <Accordion className="chosen-group filter-option-group" id="territories" label="Territories" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                            <Multiselect readonly={readonly} className="territories-container" elements={territories} selected={selectedTerritories} handleChange={(value)=>this.filterChange(value, 'territories')} options={{enableClickableOptGroups: true}}  isTerritory />
                            {!readonly && <div className="unselect-options-container">
                              <a onClick={(e)=>this.selectAllTerritoriesFilter([])}>Select All</a> 
                              <span> / </span>
                              <a onClick={(e)=>this.deselectAllTerritoriesFilter([])}>Clear All</a> 
                            </div>}
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="vendors" label="Platforms" accordion={this.state.accordion} filter={this.state.filter} totalValuesCount={vendorsForFilter.length} toggleAccordion={this.toggleAccordion}>
                            <Multiselect readonly={readonly} elements={vendorsForFilter} selected={selectedVendors} exclusiveValue={vevoYoutubeVendor} handleChange={(value)=>this.vendorChange(value)} isVendor />
                            {!readonly && <div className="unselect-options-container">
                              <a onClick={(e)=>this.vendorChange([])}>Select All</a> 
                              <span> / </span>
                              <a onClick={(e)=>this.deselectAllVendorFilter([])}>Clear All</a> 
                            </div>}
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="sources" label="Sources" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                            <Multiselect readonly={readonly} elements={sourceValues} selected={selectedSources} handleChange={(value)=>this.filterChange(value, 'sources')} isVendor />
                            {!readonly && <div className="unselect-options-container">
                                <a onClick={(e)=>this.selectAllSourcesFilter()}>Select All</a> 
                                <span> / </span>
                                <a onClick={(e)=>this.deselectAllSourcesFilter()}>Deselect All</a> 
                            </div>}
                        </Accordion>
                        <Accordion className="chosen-group filter-option-group" id="contentType" label="Content Types" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                            <Multiselect readonly={readonly} elements={contentTypes} selected={selectedContentType} handleChange={(value)=>this.filterChange(value, 'contentType')} />
                            {!readonly && <div className="unselect-options-container">
                                <a onClick={(e)=>this.selectAllContentTypesFilter()}>Select All</a> 
                                <span> / </span>
                                <a onClick={(e)=>this.deselectAllContentTypesFilter()}>Deselect All</a> 
                            </div>}
                        </Accordion>
                        
                      <Accordion className="chosen-group filter-option-group" id="audioFormats" label="Audio Formats" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                          <Multiselect readonly={readonly} elements={audioFormatValues} selected={selectedAudioFormats} handleChange={(value)=>this.filterChange(value, 'audioFormats')} />
                          {!readonly && <div className="unselect-options-container">
                              <a onClick={(e)=>this.selectAllAudioFormatsFilter()}>Select All</a> 
                              <span> / </span>
                              <a onClick={(e)=>this.deselectAllAudioFormatsFilter()}>Deselect All</a> 
                          </div>}
                      </Accordion>
                      
                      <Accordion className="chosen-group filter-option-group" id="genres" label="Genres" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                          {!readonly && <div className="date-range-holder date-range-holder-genres">
                              <div className="option option-item">
                                <div className="filter-radio-holder">
                                  <input type="radio" name="genre_mode" id="and" checked={(selectedGenresMode=='and')} onChange={()=>this.genreModeChange('and')} className="visually-hidden" />
                                  <label htmlFor="and">All genres</label>
                                </div>
                              </div>
                              <div className="option option-item">
                                <div className="filter-radio-holder">
                                <input type="radio" name="genre_mode" id="or" checked={(selectedGenresMode=='or')} onChange={()=>this.genreModeChange('or')} className="visually-hidden" />
                                  <label htmlFor="or">Any selected genres</label>
                                </div>
                                </div>
                            </div>}
                            <ReactSelect isDisabled={readonly} isMulti options={this.state.allGenres} placeholder="Search genres..." onChange={this.genreChange} defaultValue={selectedGenres} className="filter-select-container select-wrapper" />
                        </Accordion>
                        
                        <Accordion className="chosen-group filter-option-group" id="releaseDates" label="Release Date" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                            <div className="daterange-options">
                              <Multiselect readonly={readonly} elements={releaseDates} selected={selectedReleaseDate} handleChange={this.releaseDateChange} options={{disable_search: true}} />
                            </div>
                            <label className="small-text daterange-label">Custom Date Range</label>
                            <div className="daterange-item">
                              <div className="daterange-container" style={{marginBottom: (datepickerContainer=='previous'?datepickerMargin:null)}}>
                                  <Datepicker readonly={readonly} endDate={this.getMaxDate('releaseStart')} date={filter.releaseStart} label="From" dateChange={(date)=>this.dateChange(date, 'releaseStart')} placeholder="Start Date" showYearDropdown selectsStart={true} monthsShown={1} reportDates={reportDates} onExpand={(datepickerProps)=>this.onDatepickerExpand('previous', datepickerProps)} dateref={startDateRef} />
                                  <span className='fas fa-long-arrow-alt-right'></span>
                                  <Datepicker readonly={readonly} endDate={this.getMaxDate('releaseEnd')}  date={filter.releaseEnd} label="To" dateChange={(date)=>this.dateChange(date, 'releaseEnd')} placeholder="Today" className="form-control text-right" showYearDropdown selectsStart={true} monthsShown={1} dateref={releaseEndDateRef} reportDates={reportDates} onExpand={(datepickerProps)=>this.onDatepickerExpand('previous', datepickerProps)} />
                              </div>
                            </div>
                        </Accordion>
                        
                        <Accordion className="chosen-group filter-option-group" id="active" label="Engagement" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                        
                            <div className="date-range-holder date-range-holder-genres">
                                <div className="option option-item">
                                  <div className="filter-radio-holder">
                                    <input type="radio" disabled={readonly} name="engagement" id="active" checked={selectedEngagementMode} onChange={()=>this.engagementModeChange(true)} className="visually-hidden" />
                                    <label htmlFor="active">Active</label>
                                  </div>
                                </div>
                                <div className="option option-item">
                                  <div className="filter-radio-holder">
                                  <input type="radio" disabled={readonly} name="engagement" id="all" checked={!selectedEngagementMode} onChange={()=>this.engagementModeChange(false)} className="visually-hidden" />
                                    <label htmlFor="all">All</label>
                                  </div>
                                </div>                    
                            </div>
                        </Accordion>
                        {/* <Accordion className="chosen-group" id="filtered" label="Favorites" accordion={this.state.accordion} filter={this.state.filter} toggleAccordion={this.toggleAccordion}>
                          <div className="favorites-holder">
                            <div className="favorites-list">
                              <div className="favorites-item">
                                <input type="radio" disabled={readonly}  name="filtered" id="filtered_all" checked={selectedFilteredMode == undefined} onClick={()=>this.filteredModeChange(undefined)} className="visually-hidden favorites-input"/>
                                <label htmlFor="filtered_all" className="favorites-label"><span className="favorites-svg-holder" dangerouslySetInnerHTML={{__html: fullCatalogueSvg}}></span></label>
                              </div>
                              <div className="favorites-item">
                                  <input type="radio" disabled={readonly} name="filtered" id="filtered_hidden" checked={selectedFilteredMode == 'hidden'} onClick={()=>this.filteredModeChange('hidden')} className="visually-hidden favorites-input"/>
                                  <label htmlFor="filtered_hidden" className="favorites-label"><span className="favorites-svg-holder" dangerouslySetInnerHTML={{__html: withoutHiddenSvg}}></span></label>                                
                                </div>
                                <div className="favorites-item">
                                    <input type="radio" disabled={readonly} name="filtered" id="filtered_favorite" checked={selectedFilteredMode == 'favorite'} onClick={()=>this.filteredModeChange('favorite')} className="visually-hidden favorites-input"/>
                                    <label htmlFor="filtered_favorite" className="favorites-label"><span className="favorites-svg-holder" dangerouslySetInnerHTML={{__html: favoritesOnlySvg}}></span></label>
                              </div>
                            </div>
                            <div className="favorites-labels">
                              {selectedFilteredMode == undefined && <span>Full catalog</span>}
                              {selectedFilteredMode == 'hidden' && <span>Full catalog without hidden</span>}
                              {selectedFilteredMode=='favorite' && <span>Favorites only</span>}
                            </div>
                            { (selectedFilteredMode!== undefined) && <div className="filter-by date-range-holder date-range-holder-genres">
                                <b>Filter By:</b> 
                                <div className="option option-item">
                                  <div className="filter-radio-holder">
                                    <input type="radio" disabled={readonly} name="filteredBy" id="filtered_by_imprints" checked={selectedFilteredByMode=='imprints'} onClick={()=>this.filteredByModeChange('imprints')} className="visually-hidden" />
                                    <label htmlFor="filtered_by_imprints">Imprints</label>
                                  </div>
                                </div>
                                <div className="option option-item">
                                    <div className="filter-radio-holder">
                                        <input type="radio" disabled={readonly} name="filteredBy" id="filtered_by_artists" checked={selectedFilteredByMode=='artists'} onClick={()=>this.filteredByModeChange('artists')} className="visually-hidden" />
                                        <label htmlFor="filtered_by_artists">Artists</label>
                                      </div>                              
                                  </div>
                                  <div className="option option-item">
                                      <div className="filter-radio-holder">
                                      <input type="radio" disabled={readonly} name="filteredBy" id="filtered_by_products" checked={selectedFilteredByMode=='products'} onClick={()=>this.filteredByModeChange('products')} className="visually-hidden" />
                                      <label htmlFor="filtered_by_products">Products</label>
                                    </div>
                                </div>
                                <div className="option option-item">
                                    <div className="filter-radio-holder">
                                    <input type="radio" disabled={readonly} name="filteredBy" id="filtered_by_tracks" checked={selectedFilteredByMode=='tracks'} onClick={()=>this.filteredByModeChange('tracks')} className="visually-hidden" />
                                    <label htmlFor="filtered_by_tracks">Tracks</label>
                                  </div>
                              </div>                            
                            </div> }                        
                          </div>

                      </Accordion>                     */}
                      
                      <label className="limit-label">Limit</label>
                      <div className="filter-slider-container no-tooltip">
                        <Slider marks={limits} value={sliderValue} onChange={(value)=>this.limitChange(value)} step={null} max={sliderLength-1} className="filter-slider" disabled={readonly} />
                      </div>
                      {!readonly && <ul className="saved-filters">
                          {renderSavedFilters()}
                        </ul>
                      }
                    </div>
                      }
                      </div>

                  { !readonly && <ul className="saved-filters">
                    <div className="form-group button-container">
                      { isConfirmModal ? <ConfirmClick confirmRef={modalRef} extraClass="date-confirm" closeTitle="Cancel" confirmAction={(e)=>{this.filterApply(e, this.state.filter); this.closeFilter();}} confirmClass="apply-btn-confirm" confirmLabel={<button className="apply-btn" alt="apply" type="button">Apply Filter</button>} confirmPrompt={confirmPrompt} submitTitle="OK" title="Apply Filter"></ConfirmClick> : <button className="apply-btn" alt="apply" type="button" onClick={(e)=>{this.filterApply(e, this.state.filter); this.closeFilter();}}>Apply Filter</button>}
                    </div>
                      {/* <div className="form-group button-container">
                          <button className="apply-btn" alt="apply" type="button" onClick={(e)=>{this.filterApply(e, this.state.filter); this.closeFilter();}}>Apply</button>
                      </div> */}
                      <div className="form-group reset-link-container">
                          <a className="reset-filter-link" onClick={(e)=>this.filterReset(e)}>Reset to default</a>
                      </div>
                  </ul>}
                      
                </div>
              </form>
          </div>
          </ClickOutsideHolder>}
            
        </div>
    }
}

function mapDispatchToProps(dispatch){
  return {
    getFilters: () => dispatch(filterActions.getFilters()),
    getEntityFilters: () => dispatch(userEntityFiltersActions.getUserEntityFilters()),
    getSystemFilter: () => dispatch(globalFilterActions.getGlobalFilter()),
    onUpdateFilter: (id, filter) => dispatch(filterActions.updateFilter(id, filter)),
    onRemoveFilter: id => dispatch(filterActions.removeFilter(id)),
    createFilter: (filter, filterName) => dispatch(filterActions.createFilter(filter, filterName)),
    setGlobalFilter: (filter, id) => dispatch(filterActions.setGlobalFilter(filter, id)),
    toggleGlobalFilter: () => dispatch(filterActions.toggleGlobalFilter()),
    setDefaultFilter: (id, isDefault) => dispatch(globalFilterActions.setDefaultFilter(id, isDefault))
  }
      
}

function mapStateToProps(state) {
  return {
      filter: state.filter,
      savedFilters: state.filter.data,
      loading: state.filter.loading,
      user: state.user.user,
      metadataMinDate: state.stats.metadataMinDate,
      userMinDate: state.user.user.first_data_date
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter);